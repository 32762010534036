import React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { CssBaseline, Grid, CircularProgress, Typography, Icon } from '@material-ui/core';
import { AppRoute } from 'components/AppRoute';
import LandingPage from 'components/pages/LandingPage';
import CartPage from 'components/pages/CartPage';
import MainLayout from 'components/MainLayout';
import { fetchSite } from 'actions/SiteActions';
import { fetchCatalogue } from 'actions/ProductActions';
import OrderCardForm from 'components/pages/forms/OrderCardForm';
import CheckoutForm from 'components/pages/forms/CheckoutForm';
import VerifyPage from 'components/pages/VerifyPage';
import PollTransactionPage from 'components/pages/PollTransactionPage';
import ThanksPage from 'components/pages/ThanksPage';
import CheckBalancePage from 'components/pages/CheckBalancePage';
import ContactUsPage from 'components/pages/ContactUsPage';
import CollectionGroupPage from 'components/pages/CollectionGroupPage';
import CollectionPage from 'components/pages/CollectionPage';
import CustomPage from './pages/CustomPage';
import ConfirmationPage from 'components/pages/ConfirmationPage';
import SitemapPage from 'components/pages/SitemapPage';

export class Router extends React.Component {
  componentDidMount() {
    if (window.SITE_ID) {
      this.props.fetchSite(window.SITE_ID, this.props.locale);
      this.props.fetchCatalogue(window.SITE_ID, this.props.locale);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.locale !== prevProps.locale) {
      this.props.fetchSite(window.SITE_ID, this.props.locale);
      this.props.fetchCatalogue(window.SITE_ID, this.props.locale);
    }
  }

  templateComponent(customPage) {
    if (customPage.template == 'terms_and_conditions') {
      return <CustomPage content={customPage.content} name={customPage.name} page_title={customPage.page_title} description={customPage.description} block_search_engines={customPage.block_search_engines}/>
    } else if (customPage.template == 'contact_us') {
      return <ContactUsPage content={customPage.content} name={customPage.name} page_title={customPage.page_title} description={customPage.description} block_search_engines={customPage.block_search_engines} />
    } else if (customPage.template == 'sitemap') {
      return <SitemapPage name={customPage.name} pageTitle={customPage.page_title} description={customPage.description} block_search_engines={customPage.block_search_engines} />
    }
  }

  render() {
    const { isFetching, site, locale } = this.props;

    if (!window.SITE_ID) {
      return (
        <center className="under-construction">
          <Typography variant="h3" gutterBottom>Under construction</Typography>
          <Icon className="outlined" style={{fontSize: '72px'}}>construction</Icon>
        </center>
      )
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={16} justify="center">
          {!site ? //|| isFetching ?
            <Grid container spacing={16} justify="center" style={{margin: '75px'}} >
              <CircularProgress />
            </Grid>
          :
            <Switch>
              <AppRoute exact path='/' layout={MainLayout} component={() => <LandingPage site={site} />} />
              <AppRoute exact path='/physical' layout={MainLayout} component={() => <LandingPage site={site} filter="physical" />} />
              <AppRoute exact path='/digital' layout={MainLayout} component={() => <LandingPage site={site} filter="digital" />} />
              <AppRoute exact path='/card_designs/:cardDesignSlug' layout={MainLayout} component={OrderCardForm} />
              <AppRoute exact path='/cart' layout={MainLayout} component={CartPage} />
              <AppRoute exact path='/checkout' layout={MainLayout} component={CheckoutForm} />
              <AppRoute exact path='/thanks' layout={MainLayout} component={ThanksPage} />
              <AppRoute exact path='/:id/verify' layout={MainLayout} component={VerifyPage} />
              <AppRoute exact path='/:id/result' layout={MainLayout} component={PollTransactionPage} />
              {!site.disable_balance_check &&
                [<AppRoute key={1} exact path='/balance' layout={MainLayout} component={CheckBalancePage} />,
                <AppRoute key={2} exact path='/balance-check' layout={MainLayout} component={CheckBalancePage} />]
              }
              <AppRoute exact path={'/categories/:categoryName'} layout={MainLayout} component={CollectionGroupPage} />
              <AppRoute exact path={'/collections/:collectionSlug'} layout={MainLayout} component={CollectionPage} />
              {site.custom_page_groups.map((group) =>
                group.custom_pages.map((customPage, index) =>
                  customPage.template ?
                    <AppRoute key={index} exact path={'/' + customPage.path} layout={MainLayout} component={() => this.templateComponent(customPage)} />
                  :
                    <AppRoute key={index} exact path={'/' + customPage.path} layout={MainLayout} component={() => <CustomPage content={customPage.content} name={customPage.name} page_title={customPage.page_title} description={customPage.description} block_search_engines={customPage.block_search_engines} />} />
                )
              )}
              <AppRoute exact path='/orders/:reference_number/confirm' layout={MainLayout} component={ConfirmationPage} />
              <Redirect to="/" />
            </Switch>
          }
        </Grid>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.site,
    locale: state.locale.locale,
    products: state.product.products
  };
}

const mapDispatchToProps = {
  fetchSite: (id, locale) => fetchSite(id, locale),
  fetchCatalogue: (id, locale) => fetchCatalogue(id, locale)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router));
