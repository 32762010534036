import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'mui-redux-flash';
import { purchase as trackPurchase } from 'utils/tracking';
import { formatMessage } from 'utils/i18n';
import { currencyCode } from 'utils/locales';
import { clearCartSuccess } from './CartActions';
import _ from 'lodash';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const SUBMIT_ORDER_REQUEST = 'SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';
export const VERIFY_ORDER_REQUEST = 'VERIFY_ORDER_REQUEST';
export const VERIFY_ORDER_SUCCESS = 'VERIFY_ORDER_SUCCESS';
export const VERIFY_ORDER_FAILURE = 'VERIFY_ORDER_FAILURE';

function fetchOrderRequest() {
  return {
    type: FETCH_ORDER_REQUEST
  }
}

function fetchOrderSuccess(order) {
  return {
    type: FETCH_ORDER_SUCCESS,
    order
  }
}

function fetchOrderFailure() {
  return {
    type: FETCH_ORDER_FAILURE
  }
}

function submitOrderRequest() {
  return {
    type: SUBMIT_ORDER_REQUEST
  }
}

function submitOrderSuccess(order_reference_number, payment_config) {
  return {
    type: SUBMIT_ORDER_SUCCESS,
    order_reference_number,
    payment_config
  }
}

function submitOrderFailure() {
  return {
    type: SUBMIT_ORDER_FAILURE
  }
}

function verifyOrderRequest() {
  return {
    type: VERIFY_ORDER_REQUEST
  }
}

function verifyOrderSuccess() {
  return {
    type: VERIFY_ORDER_SUCCESS
  }
}

function verifyOrderFailure() {
  return {
    type: VERIFY_ORDER_FAILURE
  }
}

export function fetchOrder(id) {
  return dispatch => {
    dispatch(fetchOrderRequest());
    return axios.get('/orders/' + id)
      .then(response => {
        dispatch(fetchOrderSuccess(response.data.order));
      }).catch(error => {
        dispatch(fetchOrderFailure());
        dispatch(flashMessage(formatMessage({id: "checkout.order_not_found", defaultMessage: "Order could not be found"}), {isError: true}));
        dispatch(push('/'));
      });
  }
}

export function submitOrder(data, cart) {
  return dispatch => {
    dispatch(submitOrderRequest());
    return axios.post('/orders', {order: data})
      .then(response => {
        dispatch(submitOrderSuccess(response.data.order_reference_number, response.data.payment.config));

        if (data.payment_method == 'carrier_billing') {
          dispatch(push(`/${response.data.order_reference_number}/verify`));
          return
        }

        if (response.data.payment.pending) {
          return
        }

        trackPurchase({value: parseFloat(response.data.total_cost), currency: currencyCode(), cart: cart});

        // Reset Riskified session ID
        if (window.RISKIFIED_SESSION_ID && response.data.new_session_id) {
          RISKX.setSid(response.data.new_session_id);
          window.RISKIFIED_SESSION_ID = response.data.new_session_id;
        }
        dispatch(flashMessage(formatMessage({id: "checkout.success", defaultMessage: "Order successfully submitted"})));
        dispatch(clearCartSuccess());
        dispatch(push('/thanks'));
      }).catch(error => {
        dispatch(submitOrderFailure());
        dispatch(flashMessage(formatMessage({id: "checkout.error", defaultMessage: "Order could not be submitted."}), {isError: true, timeout: 16000}));
        window.scrollTo({top: 0, behavior: 'smooth'});
      });
  }
}

export function verifyOrder(id, data, formRef) {
  return dispatch => {
    dispatch(verifyOrderRequest());
    return axios.put('/orders/' + id + '/verify', data)
      .then(response => {
        dispatch(verifyOrderSuccess());
        dispatch(push(`/${id}/result`));
      }).catch(error => {
        dispatch(verifyOrderFailure());
        if (error.response.data.declined) {
          dispatch(flashMessage(formatMessage({id: "checkout.max_attempts", defaultMessage: "Max attempts reached, payment declined"}), {isError: true}));
          dispatch(push('/cart'));
        } else {
          formRef.current.updateInputsWithError({token: error.response.data.error})
        }
      });
  }
}

export function pollTransaction(id, intervalId, callback, cart) {
  return dispatch => {
    return axios.get('/orders/' + id + '/poll')
      .then(response => {
        if (response.data.order_reference_number) {
          clearInterval(intervalId);
          dispatch(submitOrderSuccess(response.data.order_reference_number));

          trackPurchase({value: parseFloat(response.data.total_cost), currency: currencyCode(), cart: cart});

          // Reset Riskified session ID
          if (window.RISKIFIED_SESSION_ID && response.data.new_session_id) {
            RISKX.setSid(response.data.new_session_id);
            window.RISKIFIED_SESSION_ID = response.data.new_session_id;
          }

          dispatch(flashMessage(formatMessage({id: "checkout.success", defaultMessage: "Order successfully submitted"})));
          dispatch(clearCartSuccess());
          dispatch(push('/thanks'));
        } else {
          // Order is still pending, do nothing
        }
      }).catch(error => {
        clearInterval(intervalId);
        callback();
      });
  }
}

export function validateFile(data, onSuccess, onFailure) {
  axios.post('/file_uploads', data)
    .then(response => {
      onSuccess(response);
    }).catch(error => {
      onFailure(error);
    });
}

export function confirmUpload(data) {
  return dispatch => {
    return axios.post('/confirm', data)
      .then(response => {
        dispatch(flashMessage(formatMessage({id: "checkout.image_upload_success", defaultMessage: "Image successfully uploaded"})));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(flashMessage(formatMessage({id: "checkout.image_upload_error", defaultMessage: "Image could not be uploaded, please try again"}), {isError: true}));
      });
  }
}
