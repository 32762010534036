import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Typography, Button, FormHelperText } from '@material-ui/core';
import { Helmet } from "react-helmet";
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import CheckCircle from '@material-ui/icons/CheckCircle';
import green from '@material-ui/core/colors/green';
import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';
import { createSupportLog } from 'actions/SupportActions';
import { appendSeoBrand } from 'utils/seo'
import renderHTML from 'react-render-html';
import { FormattedMessage, injectIntl } from 'react-intl';

export class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);

    this.state = {
      success: false,
      captchaResponse: null,
      captchaError: null,
      showMessage: false,
      categoryNote: null
    }
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    const { site, createSupportLog } = this.props;

    if (this.state.captchaResponse) {
      this.setState({captchaError: null});
      createSupportLog(_.merge(data, {site_id: site.id}), this.state.captchaResponse, () => this.setState({success: true}));
    } else {
      this.setState({captchaError: 'Captcha is required'});
    }
  }

  handleCategoryChange(event) {
    var cat = _.find(this.props.site.support_categories, (c) => { return c.name == event.target.value });
    this.setState({showMessage: cat.allow_message, categoryNote: cat.note});
  }

  onCaptchaChange(captchaResponse) {
    this.setState({captchaResponse: captchaResponse, captchaError: null});
  }

  render() {
    const { site, name, content, page_title, description, block_search_engines, intl } = this.props;
    const { showMessage, categoryNote } = this.state;
    const requiredValidation = { isDefaultRequiredValue: intl.formatMessage({id: "validation_required", defaultMessage: "can't be blank"})};

    return (
      <React.Fragment>
        <Helmet>
          <title>{appendSeoBrand(page_title)}</title>
          <meta name="description" content={description} />
          <meta name="robots" content={window.APP_ENV === 'production' && !block_search_engines ? 'index' : 'noindex'} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Typography variant="h2" component="h1" gutterBottom align="center">
          {name}
        </Typography>
        {content &&
          <Typography variant="subtitle1" align="center">
            {renderHTML(content)}
          </Typography>
        }
        {this.state.success ?
          <React.Fragment>
            <CheckCircle className="thanks-tick" style={{color: green[500], fontSize: 70, margin: '20px auto', display: 'block'}} />

            <Typography align="center">
              <FormattedMessage id="contact.success" defaultMessage="Thank you. We have successfully received your enquiry. A member of our team will respond to you as soon as possible"/>
            </Typography>
          </React.Fragment>
        :
          <Formsy onValidSubmit={this.handleSubmit}>
            <Grid container spacing={8} justify="center">
              <Grid item sm={8} xs={11}>
                {site.support_categories.length > 0 &&
                  <SelectInput name="category" label="Category" required
                    options={site.support_categories.map(cat => ({value: cat.name, label: cat.name}))}
                    onChange={this.handleCategoryChange}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                }

                {categoryNote &&
                  <Typography align="center" variant="h6" className="top-space">
                    {categoryNote}
                  </Typography>
                }

                {(site.support_categories.length == 0 || showMessage) &&
                  <React.Fragment>
                    <TextInput name="name"
                               label={intl.formatMessage({id: "contact.name", defaultMessage: "Name"})}
                               required
                      validationErrors={requiredValidation}
                    />

                    <TextInput type="email" name="email"
                               label={intl.formatMessage({id: "contact.email", defaultMessage: "Email"})}
                               required
                      validations={{isEmail: true}}
                      validationErrors={_.merge(requiredValidation, {
                        isEmail: intl.formatMessage({id: "contact.email_invalid", defaultMessage: "not a valid email"})
                      })}
                    />

                    <TextInput type="text" name="phone_number"
                               label={intl.formatMessage({id: "contact.phone_number", defaultMessage: "Phone Number"})}
                               required
                      validations={{isNumeric: true}}
                      validationErrors={_.merge(requiredValidation, {
                        isNumeric: intl.formatMessage({id: "contact.number_invalid", defaultMessage: "must be a number"})
                      })}
                    />

                    <TextInput type="text"
                               name="order_reference"
                               label={intl.formatMessage({id: "contact.order_id", defaultMessage: "Order ID"})}
                      validations={{isNumeric: true, isLength: 8}}
                      validationErrors={{
                        isNumeric: intl.formatMessage({id: "contact.number_invalid", defaultMessage: "must be a number"}),
                        isLength: intl.formatMessage({id: "contact.order_id_length", defaultMessage: "must be 8 digits"})
                      }}
                    />

                    <TextInput name="message"
                               label={intl.formatMessage({id: "contact.message", defaultMessage: "Message"})}
                               required multiline
                      validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                    />

                    <ReCAPTCHA
                      sitekey={window.CAPTCHA_CLIENT_KEY || ''}
                      onChange={this.onCaptchaChange}
                      className="g-recaptcha"
                      style={{marginTop: '1em'}}
                      hl={intl.locale}
                    />
                    {this.state.captchaError &&
                      <FormHelperText error>{this.state.captchaError}</FormHelperText>
                    }
                  </React.Fragment>
                }
              </Grid>
            </Grid>
            <div className="button-container">
              {(site.support_categories.length == 0 || showMessage) &&
                <Button type="submit" variant="contained" color="primary">
                  <FormattedMessage id="contact.proceed" defaultMessage="Submit"/>
                </Button>
              }
            </div>
          </Formsy>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    site: state.site.site
  };
}

const mapDispatchToProps = {
  createSupportLog: (data, captchaResponse, callback) => createSupportLog(data, captchaResponse, callback)
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactUsPage));
